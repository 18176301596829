import { useContext, useState, useEffect } from 'react';
import ResultsContext from '../../../context/ResultsContext';
import SubmissionFormContext from '../../../context/SubmissionFormContext';
import OverlayContext from '../../../context/OverlayContext';
import { useNavigate } from "react-router-dom";
import logo from '../../../images/logo.jpg';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './NavBar.scss'

export default function NavBar({ setShowContactForm }) {
  const { formContent } = useContext(SubmissionFormContext);
  const { resultsContent, updateResultsContent } = useContext(ResultsContext);
  const { showOverlay } = useContext(OverlayContext);

  const REACT_APP_PROD_URL = process.env.REACT_APP_PROD_URL;
  const DEVELOPMENT_API = process.env.REACT_APP_DEVELOPMENT_API;
  const REACT_APP_PROD_API = process.env.REACT_APP_PROD_API;
  const REACT_APP_UAT_API = process.env.REACT_APP_UAT_API;
  const REACT_APP_UAT_URL = process.env.REACT_APP_UAT_URL;
  const [API, setAPI] = useState(DEVELOPMENT_API);

  const navigate = useNavigate();
  let screenShotMessageSent = false;
  let isDownload = true;

  useEffect(() => {
    if (window.location.host === REACT_APP_PROD_URL) {
      setAPI(REACT_APP_PROD_API)
    } else if (window.location.host === REACT_APP_UAT_URL) {
      setAPI(REACT_APP_UAT_API)
    }
  }, [REACT_APP_PROD_URL, REACT_APP_PROD_API]);

  const vizFrame = document.getElementById("visualizationIFrame");
  const vizImage = document.getElementById("vizImage");
  window.addEventListener('message', message => {
    if (screenShotMessageSent && message.data.action === "__TAKE_SCREENSHOT__") {
      screenShotMessageSent = false;

      vizFrame.style.setProperty("display", "none");
      vizImage.style.setProperty("display", "block");
      vizImage.src = message.data.data;

      capturePDF();
    }

    if (message.data.action === "__LOGS_UPDATED__") {
      const data = message.data.data;
      window.kmlFileUploaded = data["kml-file-uploaded"];
      window.kmlFile = data["kml-file"];
      window.coordinates = data?.location?.coordinates;
      window.centerCoordinates = data["site-final-location"]["center-coordinates"];
      window.northEastCoordinates = data["site-final-location"]["north-east-coordinates"];
      window.southWestCoordinates = data["site-final-location"]["south-west-coordinates"];
      window.width = data["site-info"]["required-area"]["width"];
      window.height = data["site-info"]["required-area"]["height"]
      window.rotation = data["site-info"]["rotation"];
      window.kmlFile = data["kml-file"];
    }
  });

  const sendVisualisationScreenShotMessage = () => {
    vizFrame.contentWindow.postMessage({ "action": "__TAKE_SCREENSHOT__", "data": "" }, "*");
    screenShotMessageSent = true;
  };

  const downloadPdf = () => {
    isDownload = true;
    sendVisualisationScreenShotMessage();
  }

  const getQuote = () => {
    console.log("disable");
    document.querySelector(".red-card-button").style.setProperty("pointer-events", "none");
    document.querySelector(".red-card-button").style.setProperty("opacity", ".8");
    isDownload = false;
    sendVisualisationScreenShotMessage();
  }

  const capturePDF = () => {
    let resultsWrapper = document.querySelector(".results-wrapper");
    let resultsWrapperMaxW = document.querySelector(".results-max-w");
    let submissionForm = document.querySelector(".SubmissionForm");
    let navBar = document.querySelector(".NavBar");
    let openConfig = document.querySelector(".show-config");
    let bolEolOffset = window.getComputedStyle(document.querySelector('.toggle_bol_eol_flag'), ':before').getPropertyValue("--highlight-x-pos")
    let augmentationOffset = window.getComputedStyle(document.querySelector('.toggle_augmentation'), ':before').getPropertyValue("--highlight-x-pos")

    let stickySubmit = document.querySelector(".sticky-submit");
    let pdfLogo = document.querySelector(".pdfLogo");
    let changeOverlay = document.querySelector(".changeOverlay");
    let submitSection = document.querySelector(".submit-section");

    if (bolEolOffset === "58px") {
      document.querySelector('.toggle_bol_eol_flag').style.setProperty("--highlight-x-pos", "25.5px");
    } else {
      document.querySelector('.toggle_bol_eol_flag').style.setProperty("--highlight-x-pos", "3px");
    }
    if (augmentationOffset === "58px") {
      document.querySelector('.toggle_augmentation').style.setProperty("--highlight-x-pos", "25.5px");
    } else {
      document.querySelector('.toggle_augmentation').style.setProperty("--highlight-x-pos", "3px");
    }

    if (!document.querySelector(".show-config").classList.contains("open")) {
      document.querySelector(".show-config").click();
    }

    resultsWrapper.style.overflow = "visible";
    resultsWrapper.style.height = "2070px";
    resultsWrapper.style.backgroundColor = "rgba(0,56,117,.1)";
    resultsWrapper.style.width = "fit-content";
    resultsWrapper.style.marginLeft = "0";
    resultsWrapper.style.paddingRight = "20px";
    resultsWrapper.style.paddingTop = "10px";

    resultsWrapperMaxW.style.paddingBottom = "80px";

    submissionForm.style.paddingLeft = "20px";
    submissionForm.style.paddingTop = "20px";
    submissionForm.style.height = "fit-content";
    submissionForm.style.overflow = "visible";
    submissionForm.style.top = "0";

    if (changeOverlay) {
      changeOverlay.style.width = "1000px";
      changeOverlay.style.right = "0px";
      changeOverlay.style.position = "absolute";
    }

    submitSection.style.display = "none";

    stickySubmit.style.position = "";

    pdfLogo.style.display = "block";

    navBar.style.display = "none";

    openConfig.style.display = "none";

    document.body.style.backgroundColor = "white";

    setTimeout(async () => {
      const canvas = await html2canvas(document.body, { windowWidth: 1600, windowHeight: 2070 });
      const imgData = canvas.toDataURL('image/png');

      if (isDownload) {
        const pdf = new jsPDF('portrait');
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = pdf.internal.pageSize.getWidth();
        const xPos = 0;
        const yPos = 0;
        pdf.addImage(imgData, 'PNG', xPos, yPos, imgWidth, pdfHeight);
        pdf.save(`AESI_TeraStor_sizing_estimate_${formContent.rated_system_power_mw}MW_${formContent.discharge_duration}MWh.pdf`);
      } else {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'gC0WrwRfg57dGcjlHIFEIaIfO0z4AsHe7KqVWacV'
          },
          body: JSON.stringify({ file: imgData })
        };

        try {
          const response = await fetch(`${API}/fkrwhtv`, requestOptions);
          const imageURL = await response.json();

          window.imageUrl = imageURL.url

          setShowContactForm(true);
        } catch (e) {
          console.error(e);
        }
      }

      resultsWrapper.style.removeProperty("overflow");
      resultsWrapper.style.removeProperty("left");
      resultsWrapper.style.removeProperty("height");
      resultsWrapper.style.removeProperty("background-color");
      resultsWrapper.style.removeProperty("width");
      resultsWrapper.style.removeProperty("margin-left");
      resultsWrapper.style.removeProperty("padding-right");
      resultsWrapper.style.removeProperty("padding-top");

      resultsWrapperMaxW.style.removeProperty("width");
      resultsWrapperMaxW.style.removeProperty("padding-bottom");

      submissionForm.style.removeProperty("width");
      submissionForm.style.removeProperty("height");
      submissionForm.style.removeProperty("max-width");
      submissionForm.style.removeProperty("overflow");
      submissionForm.style.removeProperty("top");
      submissionForm.style.removeProperty("padding-left");
      submissionForm.style.removeProperty("padding-top");
      submissionForm.style.removeProperty("padding-bottom");

      if (changeOverlay) {
        changeOverlay.style.removeProperty("width");
        changeOverlay.style.removeProperty("right");
        changeOverlay.style.removeProperty("position");
      }

      submitSection.style.removeProperty("display");

      stickySubmit.style.position = "sticky";

      pdfLogo.style.setProperty("display", "none");

      navBar.style.removeProperty("display");

      openConfig.style.removeProperty("display");

      vizFrame.style.setProperty("display", "block")
      vizImage.style.setProperty("display", "none");

      document.body.style.removeProperty("background-color");
      document.body.style.removeProperty("width");
      document.body.style.removeProperty("max-width");
      document.querySelector('.toggle_bol_eol_flag').style.removeProperty("--highlight-x-pos");
      document.querySelector('.toggle_augmentation').style.removeProperty("--highlight-x-pos");

      console.log("enable");
      document.querySelector(".red-card-button").style.removeProperty("pointer-events");
      document.querySelector(".red-card-button").style.removeProperty("opacity");
    }, 200)
  };

  return (
    <div className="NavBar" id="NavBar">
      <img onClick={() => navigate("/")} src={logo} alt="logo" />
      {resultsContent.service_life ?
        <div style={{ display: "flex", alignItems: "center" }}>
          {showOverlay ?
            <>
              <button className="disabled">GET QUOTE</button>
              <button className="disabled" style={{ marginLeft: "1em" }}>DOWNLOAD PDF</button>
            </>
            :
            <>
              <button className="red getquote" onClick={getQuote}>GET QUOTE</button>
              <button style={{ marginLeft: "1em" }} onClick={downloadPdf}>DOWNLOAD PDF</button>
            </>
          }
        </div>
        : null
      }
    </div >
  )
}